const RoundedTick = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17.6368"
        cy="17.3438"
        r="17.3438"
        fill="var(--color-primary)"
      />
      <rect
        x="6.63672"
        y="17.0635"
        width="4.0028"
        height="11.9675"
        rx="1.19675"
        transform="rotate(-45 6.63672 17.0635)"
        fill="white"
      />
      <rect
        x="25.8105"
        y="9.16113"
        width="3.99529"
        height="19.148"
        rx="1.19675"
        transform="rotate(45 25.8105 9.16113)"
        fill="white"
      />
    </svg>
  );
};

export default RoundedTick;
