import React, { useMemo } from "react";

import { Privilege } from "../../../types/enum.types";
import { SubscriptionType } from "../../../types/subscription.type";
import Tick from "../../assets/icons/Tick";
import SwitchInput from "../../Input/SwitchInput";
import styles from "../SubscriptionCard.module.scss";

interface Props {
  entreprise?: boolean;
  privilege: Privilege;
  title: string;
  description: string;
  price: number;
  formerPrice: number;
  bulletPoints: string[];
  type?: SubscriptionType;
  showType?: boolean;
  size?: "small" | "medium" | "large";
  onClick: () => void;
  setType?: (type: SubscriptionType) => void;
  hasJobslot?: boolean;
  activeEntreprise?: boolean;
}

const SubscriptionCard: React.FC<Props> = ({
  entreprise = false,
  activeEntreprise = false,
  privilege,
  title,
  description,
  price,
  formerPrice,
  bulletPoints,
  type,
  showType,
  size = "large",
  onClick,
  setType,
  hasJobslot = false,
}) => {
  const buttonText = () => {
    if (privilege === Privilege.JOBSLOT) {
      if (hasJobslot) {
        return "Désactiver";
      } else {
        return "Activer";
      }
    }
    if (privilege === Privilege.ENTREPRISE) {
      if (activeEntreprise) {
        return "Se désabonner";
      } else {
        return "S'abonner";
      }
    }
    return "S'abonner";
  };

  const idDiscounted = useMemo(
    () => formerPrice && formerPrice > price,
    [formerPrice, price]
  );

  return (
    <div
      className={`${styles.subscription_card_container} ${
        size == "small"
          ? styles.subscription_card_container_small
          : size == "medium"
          ? styles.subscription_card_container_medium
          : ""
      }`}
    >
      {entreprise && <div className={styles.greyed_out}></div>}
      <div className={styles.subscription_card_title}>
        <h2>{title}</h2>
        {showType && <div>{type}</div>}
      </div>
      <p className={styles.subscription_card_description}>{description}</p>
      {entreprise ? (
        <h3>Inclus dans l'abonnement Entreprise</h3>
      ) : (
        <h3>
          {idDiscounted && <s>{formerPrice}€ HT</s>} {price}€ HT
          <span>/mois</span>
        </h3>
      )}
      {!entreprise && (
        <button
          onClick={() => {
            onClick();
          }}
          className={
            entreprise ? styles.grey_btn : styles.subscription_card_button
          }
        >
          {buttonText()}
        </button>
      )}
      <br />
      <br />
      {bulletPoints.map((bulletPoint, index) => (
        <div key={index} className={styles.subscription_card_bullet}>
          <Tick />
          <p>{bulletPoint}</p>
        </div>
      ))}

      {setType && (
        <div className={styles.subscription_card_type}>
          <SwitchInput
            val={type === "Mensuelle" ? 1 : 2}
            onChange={(val) => {
              setType(val === 1 ? "Mensuelle" : "Annuelle ");
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
