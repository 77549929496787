import { FunctionComponent, ReactNode } from "react";

import styles from "./index.module.scss";

interface Props {
  children: ReactNode;
  large?: boolean;
}
const ComingSoonSticker: FunctionComponent<Props> = ({
  children,
  large = false,
}) => {
  return (
    <div className={large ? styles.large : styles.container}>
      {children}
      <div className={styles.fullContainer}></div>
      <div className={styles.banner}>Bientôt disponible</div>
    </div>
  );
};

export default ComingSoonSticker;
