import { CircularProgress } from "@mui/material";

import styles from "./Loading.module.scss";
function Loading() {
  return (
    <div className={styles.loading_container}>
      <CircularProgress disableShrink size={"70px"} />
    </div>
  );
}

export default Loading;
