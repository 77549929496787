import { Modal } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import useAuth from "../../hooks/useAuth";
import { Privilege, RoleEnum } from "../../types/enum.types";
import {
  APPLICANT_HOME_PAGE,
  ENTREPRISE_OFFERS_PAGE,
  LOGIN_PAGE,
} from "../../utils/pages";
import Loading from "../Loading";
import SubscriptionModal from "../SubscriptionModal";
import styles from "./Page.module.scss";
interface Props {
  children: React.ReactNode;
  background?: string;
  roles: RoleEnum[];
  privileges?: Privilege[];
}

export const Page = styled.div<{ background?: string }>`
  position: relative;
  background: ${(props) => props.background || "white"};
  min-height: 100vh;
  overflow-x: hidden;
`;

const CustomModal = styled(Modal)<{ backdropFilter: string }>`
  backdrop-filter: ${(props) => props.backdropFilter ?? "none"};
`;

// If the user is authenticated then he doesn't have the right to enter the page
const PrivatePage: React.FC<Props> = ({
  children,
  roles,
  background,
  privileges,
}) => {
  const { user, isAuthenticated } = useAuth();
  const router = useRouter();
  const [modal, setModal] = useState(true);
  const hasEntreprise = !!user?.company?.privileges?.includes(
    Privilege.ENTREPRISE
  );

  const hasNotPermission = useMemo(
    () => isAuthenticated && user && !roles.includes(user.role),
    [isAuthenticated, user]
  );

  useEffect(() => {
    if (typeof isAuthenticated === "undefined") return;
    if (!isAuthenticated) router.push(LOGIN_PAGE);
    if (hasNotPermission) {
      const route =
        user?.role === RoleEnum.COMPANY
          ? ENTREPRISE_OFFERS_PAGE
          : APPLICANT_HOME_PAGE;
      router.push(route);
    }
  }, [isAuthenticated, user]);

  if (!isAuthenticated || hasNotPermission) return <Loading />;
  if (privileges && privileges.length > 0) {
    if (
      !user?.company?.privileges ||
      !privileges.every((p) => user.company?.privileges?.includes(p))
    )
      return (
        <CustomModal open={modal} backdropFilter="blur(40px)">
          <div className={styles.modalContainer}>
            <SubscriptionModal
              entreprise={hasEntreprise ?? false}
              onClose={() => {
                setModal(false);
                router.push(ENTREPRISE_OFFERS_PAGE);
              }}
            />
          </div>
        </CustomModal>
      );
  }
  return <Page background={background}>{children}</Page>;
};

export default PrivatePage;
