const Tick = () => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="7.98808"
        width="4.0028"
        height="11.9675"
        rx="1.19675"
        transform="rotate(-45 0 7.98808)"
        fill="var(--color-primary)"
      />
      <rect
        x="19.1738"
        y="0.0856934"
        width="3.99529"
        height="19.148"
        rx="1.19675"
        transform="rotate(45 19.1738 0.0856934)"
        fill="var(--color-primary)"
      />
    </svg>
  );
};

export default Tick;
