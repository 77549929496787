type Props = {
  className?: string;
  onClick?: () => void;
};

const CloseModal = ({ className, onClick }: Props) => {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="47" height="47" rx="8" fill="var(--color-primary)" />
      <path
        d="M31.9041 15.3323C31.2504 14.6785 30.1904 14.6785 29.5367 15.3323L23.6189 21.2505L17.7009 15.3321C17.0472 14.6783 15.9872 14.6783 15.3335 15.3321C14.6797 15.9859 14.6797 17.0459 15.3335 17.6997L21.2514 23.6181L15.3321 29.5379C14.6784 30.1916 14.6784 31.2517 15.3321 31.9054C15.9859 32.5592 17.0458 32.5592 17.6995 31.9054L23.6189 25.9857L29.5381 31.9053C30.1918 32.5591 31.2517 32.5591 31.9055 31.9053C32.5592 31.2515 32.5592 30.1915 31.9055 29.5377L25.9863 23.6181L31.9041 17.6999C32.5578 17.0461 32.5578 15.9861 31.9041 15.3323Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseModal;
