import React from "react";

import styles from "./SwitchInput.module.scss";

interface Props {
  onChange: (value: number) => void;
  val: number;
}

const SwitchInput: React.FC<Props> = ({ val, onChange }) => {
  return (
    <div className={styles.switch_input_container}>
      <div
        onClick={() => {
          onChange(1);
        }}
        className={`${val === 1 && styles.switch_input_active}`}
      >
        Mensuelle
      </div>
      <div
        onClick={() => {
          onChange(2);
        }}
        className={`${val === 2 && styles.switch_input_active}`}
      >
        Annuelle
      </div>
    </div>
  );
};

export default SwitchInput;
