import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  width: string;
  padding?: string;
  disabled?: boolean;
  background?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  href?: string;
}

interface ButtonProps {
  width: string;
  disabled?: boolean;
  padding: string;
  background?: string;
}

const NoStyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Button = styled.button<ButtonProps>`
  width: ${(props) => props.width};
  margin: 10px;
  padding: ${(props) => props.padding};
  border: none;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  border-radius: 7px;
  background-color: ${(props) =>
    props.disabled
      ? "var(--color-disabled-border)"
      : props.background ?? "var(--color-primary)"};
  color: white;
  font-weight: bold;
  font-family: inherit;
  @media screen and (max-width: 768px) {
    margin: 10px 0 10px 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const PrimaryButton: React.FC<Props> = ({
  label,
  padding = "3%",
  width,
  disabled = false,
  background,
  style,
  loading,
  loadingComponent,
  type,
  onClick,
  href,
}) => {
  return href ? (
    <NoStyledLink href={href} target="_blank" rel="noreferrer">
      <Button
        background={background}
        disabled={disabled}
        width={width}
        padding={padding}
        onClick={onClick}
        style={style}
        type={type}
      >
        {loadingComponent ? (
          loadingComponent
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          label
        )}
      </Button>
    </NoStyledLink>
  ) : (
    <Button
      background={background}
      disabled={disabled}
      width={width}
      padding={padding}
      onClick={onClick}
      style={style}
      type={type}
    >
      {loadingComponent ? (
        loadingComponent
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        label
      )}
    </Button>
  );
};

export default PrimaryButton;
