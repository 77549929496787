import styled from "styled-components";

import CloseModal from "../assets/icons/CloseModal";
import Subscriptions from "../subscription/Subscriptions";

const SubscriptionModalContainer = styled.div`
  position: relative;
  width: 65%;
  margin: auto;
  background: white;
  margin-top: 50px;
  padding: 1rem;
  border-radius: 7px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: -2%;
  right: -2%;
`;

const SubscriptionTitle = styled.h1`
  font-family: var(--font-title-bold);
  text-align: center;
  margin-top: 2rem;
  color: var(--color-dark-1);
`;

const SubscriptionParagraph = styled.p`
  font-family: var(--font-title-bold);
  text-align: center;
  margin-top: 2rem;

  color: var(--color-dark-1);
`;

interface Props {
  onClose: () => void;
  entreprise: boolean;
}

const SubscriptionModal: React.FC<Props> = ({ onClose, entreprise }) => {
  return (
    <SubscriptionModalContainer>
      <CloseIcon
        onClick={() => {
          onClose();
        }}
      >
        <CloseModal />
      </CloseIcon>
      <SubscriptionTitle>Offres entreprise</SubscriptionTitle>
      <SubscriptionParagraph>
        Choisissez l’abonnement qui vous convient et profitez des fonctionnalité
        de Swala
      </SubscriptionParagraph>

      <Subscriptions size="medium" entreprise={entreprise} />
    </SubscriptionModalContainer>
  );
};

export default SubscriptionModal;
