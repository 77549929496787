import React from "react";
import styled from "styled-components";

import CloseIcon from "../../assets/icons/CloseIcon";
import PrimaryButton from "../../buttons/PrimaryButton";

const ConfirmationContainer = styled.div`
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 75px 30px;
  text-align: center;
  color: var(--color-primary);
  font-family: var(--font-title-bold);
  font-size: 80%;
  border-radius: 7px;

  /* large  */
  @media screen and (max-width: 992px) {
    font-size: 140%;
    width: 95%;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
`;

const Button = styled(PrimaryButton)`
  color: white;
  padding: 0.75rem 0;
`;

const CenterButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props {
  message: string;
  BottomComponent?: React.ReactNode;
  secondMessage?: string;
  confirm?: () => void;
  close: () => void;
}

const Confirmation: React.FC<Props> = ({
  message,
  BottomComponent,
  secondMessage,
  close,
  confirm,
}) => {
  return (
    <ConfirmationContainer>
      <CloseIconContainer onClick={close}>
        <CloseIcon />
      </CloseIconContainer>
      <h1>{message}</h1>
      <h1>{secondMessage}</h1>
      <CenterButtons>
        {BottomComponent && (
          <BottomComponentContainer>{BottomComponent}</BottomComponentContainer>
        )}
        {confirm && <Button onClick={confirm} width="30%" label="OK" />}
      </CenterButtons>
    </ConfirmationContainer>
  );
};

export default Confirmation;
