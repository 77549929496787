import gql from "../../apollo.setup";

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod(
    $CreatePaymentMethodInput: CreatePaymentMethodInput!
  ) {
    createPaymentMethod(CreatePaymentMethodInput: $CreatePaymentMethodInput) {
      paymentMethodId
      status
      next_action {
        redirect_to_url {
          url
          return_url
        }
        type
      }
    }
  }
`;

export const GET_PAYMENT_METHOD_STATUS = gql`
  mutation GetPaymentMethodStatus(
    $GetPaymentMethodStatusInput: GetPaymentMethodStatusInput!
  ) {
    getPaymentMethod(GetPaymentMethodStatusInput: $GetPaymentMethodStatusInput)
  }
`;
export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod(
    $DeletePaymentMethodInput: DeletePaymentMethodInput!
  ) {
    deletePaymentMethod(DeletePaymentMethodInput: $DeletePaymentMethodInput)
  }
`;

export const ACTIVATE_PAYMENT_METHOD = gql`
  mutation ActivatePaymentMethod(
    $ActivatePaymentMethodInput: ActivatePaymentMethodInput!
  ) {
    activatePaymentMethod(
      ActivatePaymentMethodInput: $ActivatePaymentMethodInput
    )
  }
`;

export const GET_COMPANY_SUBSCRIPTIONS = gql`
  query {
    getCompanySubscriptions {
      id
      stripeSubscriptionId
      subscriptionType
      status
      unsubscribed
      validUntilDate
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation Unsubscribe($UnsubscribeInput: UnsubscribeInput!) {
    unsubscribe(UnsubscribeInput: $UnsubscribeInput)
  }
`;

export const TOGGLE_JOBSLOT = gql`
  mutation {
    toggleJobSlot
  }
`;
