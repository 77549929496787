import { useMutation, useQuery } from "@apollo/client";
import { Modal } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import {
  GET_COMPANY_SUBSCRIPTIONS,
  UNSUBSCRIBE,
} from "../../../api/graphql/subscription";
import useAuth from "../../../hooks/useAuth";
import { Privilege } from "../../../types/enum.types";
import {
  GetCompanySubscriptionsResponse,
  SubscriptionType,
  UnsubscribeRequest,
} from "../../../types/subscription.type";
import { getCompanyDiscount } from "../../../utils/constants";
import { subscriptionMapping } from "../../../utils/mapping";
import { formatFullDate } from "../../../utils/utils";
import RoundedTick from "../../assets/icons/RoundedTick";
import Confirmation from "../../profile/utils/Confirmation";
import ComingSoonSticker from "../../SubscriptionSettings/Abonnement/ComingSoonSticker";
import SubscriptionCard from "../SubscriptionCard";
import styles from "../SubscriptionCard.module.scss";

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 50px auto;
  @media screen and (max-width: 992px) {
    gap: 10px;
    flex-direction: column;
  }
`;

interface Props {
  size?: "small" | "medium" | "large";
  entreprise: boolean;
}

const Subscriptions: React.FC<Props> = ({ size = "large", entreprise }) => {
  const [type, setType] = useState<SubscriptionType>("Mensuelle");
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [unsubscribeTrigger] = useMutation<never, UnsubscribeRequest>(
    UNSUBSCRIBE
  );
  const { user } = useAuth();

  const { data, refetch } = useQuery<GetCompanySubscriptionsResponse, never>(
    GET_COMPANY_SUBSCRIPTIONS
  );

  const subscription = useMemo(
    () =>
      data?.getCompanySubscriptions?.find(
        (sub) => sub.subscriptionType === "ENTREPRISE"
      ),
    [data?.getCompanySubscriptions]
  );

  const unsubscribed = useMemo(
    () => !subscription || subscription?.unsubscribed,
    [subscription]
  );

  const unsubscribe = useCallback(async () => {
    if (subscription && !subscription.unsubscribed) {
      await unsubscribeTrigger({
        variables: { UnsubscribeInput: { subscriptionId: subscription.id } },
      });
      setUnsubscribeModal(true);
      refetch();
    }
  }, [subscription]);

  const openCalendly = () => {
    const url = process.env.NEXT_PUBLIC_CALENDLY_URL;
    if (url) window.open(url, "_blank");
  };

  return (
    <Flex>
      <div
        className={`${styles.defaultCheckDiv} ${
          entreprise ? styles.checkedDiv : ""
        }`}
      >
        {entreprise && (
          <span className={styles.checkMarkDiv}>
            <RoundedTick />
          </span>
        )}
        <SubscriptionCard
          activeEntreprise={!unsubscribed}
          privilege={Privilege.ENTREPRISE}
          size={size}
          title={subscriptionMapping(getCompanyDiscount(user)).ENTREPRISE.label}
          description="Mettez en avant votre marque employeur"
          price={subscriptionMapping(getCompanyDiscount(user)).ENTREPRISE.value}
          formerPrice={subscriptionMapping().ENTREPRISE.value}
          onClick={() => {
            if (!entreprise || unsubscribed) openCalendly();
            else unsubscribe();
          }}
          bulletPoints={[
            "Création dʼune page entreprise",
            "Dépot illimité dʼoffres dʼemploi (jusqu'à 100 offres en simultané)",
            "Outil de gestion pour les candidatures",
          ]}
        />
      </div>
      <ComingSoonSticker large>
        <div className={styles.comingSoon}>
          <SubscriptionCard
            privilege={Privilege.CVTHEQUE}
            size={size}
            title={"CVthèque "}
            description="Trouvez le meilleur candidat"
            price={
              type === "Mensuelle"
                ? subscriptionMapping(getCompanyDiscount(user)).CVTHEQUE_MONTHLY
                    .value
                : subscriptionMapping(getCompanyDiscount(user)).CVTHEQUE_YEARLY
                    .value
            }
            formerPrice={
              type === "Mensuelle"
                ? subscriptionMapping().CVTHEQUE_MONTHLY.value
                : subscriptionMapping().CVTHEQUE_YEARLY.value
            }
            onClick={() => {
              // redirectToCalendly();
            }}
            bulletPoints={[
              "Accès à la CVthèque en illimité",
              "Profils ciblés grâce à lʼalgorithme",
            ]}
            type={type}
            setType={(type) => {
              setType(type);
            }}
          />
        </div>
      </ComingSoonSticker>
      <Modal open={unsubscribeModal} onClose={() => setUnsubscribeModal(false)}>
        <Confirmation
          close={() => setUnsubscribeModal(false)}
          message={`Votre abonnement a été résilié avec succès.${
            subscription?.validUntilDate &&
            ` Vous bénéficierez de l'accès entreprise jusqu'au ${formatFullDate(
              subscription?.validUntilDate
            )}`
          }`}
        />
      </Modal>
    </Flex>
  );
};

export default Subscriptions;
